.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

table{
  /*max-height: 100px;*/
  width:30px;
  overflow-y: scroll;
  counter-reset: section;

}

.count:before {
  counter-increment: section;
  content: counter(section);
}

tbody{
  /* display: block; */
  overflow-y: auto;

}

.react-bootstrap-table table {
  width: max-content;
}


thead tr:nth-child(1) th{
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-responsive{
  /*table-layout: fixed;
  height: 100%;
  width: 100%;*/
  /*overflow:scroll;*/
  height:400px;  
  overflow-y: auto;
}

/* .App {
  font-family: sans-serif;
  text-align: center;
} */

.searchText{
    height: 30px;
  
  }
  
  .searchTable{
    display: flex;
    justify-content: space-between;
  }
  
  #download{
    display: none;
  }

  .buttoncolor{
    color: white;
    background-color: #0079FF;
  }
  
  .buttoncolor:hover{
   color: white !important; 
  }